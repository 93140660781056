import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAssetsByUserId } from '../../action';
import { Button } from "react-bootstrap";

import Dropzone from "../../components/dropZone";

const Assets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const assets = useSelector((state) => state.assets);

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  

  useEffect(() => {
    dispatch(getAssetsByUserId(user.user_id));
  }, [user, user.user_id, showModal, dispatch]);

  const handleClick = (id) => {
    navigate(`/asset-viewer/${id}`);
  }

  return (
    <>
      <div className="assets blur-glass">Your Collections</div>
      <div className="container collection-list">
       {
        assets.length > 0 && assets.map((asset, index) => {
          return(<>
            <div key={asset.asset_id} className="asset-card light-glass" onClick={() => handleClick(asset.asset_id)}>{asset.asset_name}</div>
          </>)
        })
       }
      </div>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Upload Files
      </Button>
       <Dropzone show={showModal} handleClose={handleClose} />
    </>
  );
};
export default Assets;
