import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from '../../action';
import {  Button } from "react-bootstrap";

const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

    return <>
      <h1>Hello {user.name}</h1>
      <Button variant="primary" size="sm" onClick={()=>{dispatch(logout(user))}}>
        Logout
      </Button>
    </>
}
export default Account