import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { loginSuccess } from '../../action';
import './login.css';
import RegistrationForm from './registration';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [register, setRegister] = useState(false);

  const handleLogin = async () => {
    if (username && password) {
      setMessage('Logging in ....');
      try {
        const response = await axios.post('https://mirage-service.azurewebsites.net/useraccount/login', {
          identifier: username,
          password: password,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!(response.status === 200)) {
          setMessage('Invalid Credentials');
          throw new Error('Login failed');
        }

        dispatch(loginSuccess(response.data.user));
        navigate('/home');
      } catch (error) {
        console.error('Error during login:', error);
      }
    } else {
      setMessage('Invalid Credentials');
    }
  };

  return (
    <div className='login'>
      {register ? (
       <RegistrationForm register={(result)=>{
        setMessage(result.message)
        setRegister(false)
       }}/>
      ):(    
        <div class='login-card'>
          <h1>Login</h1>
          {message && <p>{message}</p>}
          {/* <h2 className='login-subheader'>Log in to the Matrix</h2> */}
          <input
            type='text'
            placeholder='Username'
            className='input-field'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type='password'
            placeholder='Password'
            className='input-field'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className='login-button' onClick={handleLogin}>
            Login
          </button>
          <button className='login-button mt-2' onClick={() => setRegister(true)}>
            Register
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
