import axios from 'axios';

const api = axios.create({
  baseURL: 'https://mirage-service.azurewebsites.net',
  headers: {
    // 'Content-Type': 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

export default api;
