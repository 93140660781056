import React from "react";
import GlbViewer from "../../components/glbViewer";

const Home = () => {
  return (
    <>
      <div className="titlebox">
        <div className="title">
          <div>
            <h1 className="title-text">LOREM IPSUM</h1>
            <p className="title-intro-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div className="title-icon">
          <div className="title-3d-viewer">
           {/* <GlbViewer /> */}
          </div>
        </div>
      </div>
      <div className="imagination-box">
        <h1>Explore the new world of </h1>
        <h1 className="imagination-text">IMAGINATION</h1>
      </div>
      <div className="d-flex justify-content-around align-items-center mt-4 mb-4 pt-4">
        <div className="title-screen-cards blur-glass">title-screen-cards 1</div>
        <div className="title-screen-cards blur-glass">title-screen-cards 2</div>
        <div className="title-screen-cards blur-glass">title-screen-cards 3</div>
        <div className="title-screen-cards blur-glass">title-screen-cards 4</div>
      </div>
    </>
  );
};
export default Home;
