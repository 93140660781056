import { fetchAssets } from "./services/assetsService";

export const loginSuccess = (user) => ({
  type: "LOGIN_SUCCESS",
  payload: user,
});

export const logout = () => ({
  type: "LOGOUT",
});

const getAssetsByUserIdSuccess  = (assets) => ({
  type: "USER_ASSETS",
  payload: assets,
});

export const getAssetsByUserId = (user_id) => {
  return (dispatch) => {
    fetchAssets(user_id)
      .then(response => {
        dispatch(getAssetsByUserIdSuccess(response.data));
      })
      .catch(error => {
        console.log(error)
      });
  };
};
