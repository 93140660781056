import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";
import { uploadAssets } from "../services/assetsService";

const Dropzone = ({ show, handleClose }) => {
  const user = useSelector((state) => state.user);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');

  const [formData, setFormData] = useState({
    user_id: user.user_id,
    asset_name: "Foxy 2",
    asset_path: "",
    asset_type: "glb",
    asset_size: "14000",
  });

  const UploadFiles = async () => {
    setMessage('uploading file... please wait')
    const data = new FormData();

    data.append("user_id", formData.user_id);
    data.append("asset_path", formData.asset_path);
    data.append("asset_type", formData.asset_type);

    files.forEach((file) => {
      data.append("file", file);
      data.append("asset_name", file.name);
      data.append("asset_size", file.size);
    });

    try {
      await uploadAssets(data);
      setFiles([]);
      handleClose();
    } catch (error) {
      setMessage('Error uploading file')
      console.error("Error uploading file:", error);
    }
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Upload Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {message && <p>{message}</p>}
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            border: "2px dashed #ccc",
            borderRadius: "4px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <p>Drag and drop files here, or click to select files</p>
          <input
            accept=".glb,.glbt"
            type="file"
            onChange={(e) =>
              setFiles([...files, ...Array.from(e.target.files)])
            }
            style={{ display: "none" }}
            id="fileInput"
          />
          <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
            Select Files
          </label>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setFiles([]);
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!(files.length > 0)}
          variant="primary"
          onClick={() => UploadFiles()}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Dropzone;
