import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('reduxState', serializedState);
    } catch (err) {
      console.log(err);
    }
  };

const initialState = {
  isAuthenticated: false,
  user: null,
  assets: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true, user: action.payload };
    case 'LOGOUT':
      return { ...state, isAuthenticated: false, user: null };
    case 'USER_ASSETS': 
      return { ...state, assets: action.payload};
    default:
      return state;
  }
};

const store = createStore(authReducer, loadState(), applyMiddleware(thunk));

store.subscribe(() => {
    saveState(store.getState());
  });
  

export default store;
