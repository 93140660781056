import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import GlbViewer from "../../components/glbViewer";
import "./asset.css";

const AssetViewer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Button onClick={handleClick}>close</Button>
      <div>
        <GlbViewer
          className="glbviewer light-glass"
          url={`https://mirageassets.blob.core.windows.net/mirage-asset-cupboard/${params.id}.glb`}
        />
      </div>
    </>
  );
};
export default AssetViewer;
