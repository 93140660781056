import { Outlet } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import ParticleCanvas from "./particle";

const DefaultLayout = () => (
  <>
    <ParticleCanvas />
    <div className="app-body">
      <Header />
      <Outlet />
      <Footer />
    </div>
  </>
);
export default DefaultLayout;
