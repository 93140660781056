import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Menu = require("../data/menu.json");

const Header = () => {
  let navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  const redirectTo = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-2">
        <div className="header blur-glass">
          {Menu.map((item) => {
            return (
              <>
              {
                !(item.protected !== isAuthenticated ) && <div className="p-2 header-menu" onClick={() => redirectTo(item.path)}>
                {item.title}
              </div>
              }
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Header;
