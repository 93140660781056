import React, { useState } from 'react';
import axios from 'axios';

const RegistrationForm = (props) => {
  const [userData, setuserData] = useState({
    name: '',
    phone: '',
    email: '',
    profile_pic_url: '',
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setuserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !userData.username ||
      !userData.password ||
      !userData.name ||
      !userData.phone
    ) {
      alert('Please fill in all mandatory fields.');
      return;
    }

    try {
      const response = await axios.post(
        'https://mirage-service.azurewebsites.net/useraccount',
        userData
      );
      props.register({message:`Registration Successful. Please login with username ${response.data.username}`})
    } catch (error) {
      console.error('Error during registration:', error);
      props.register({message:'Registration Failed'})
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='p-4 border rounded bg-light'
      style={{ maxWidth: '600px', margin: 'auto' }}
    >
      <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Name:</label>
        <div className='col-sm-9'>
          <input
            type='text'
            name='name'
            value={userData.name}
            onChange={handleChange}
            required
            className='form-control'
          />
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Phone:</label>
        <div className='col-sm-9'>
          <input
            type='text'
            name='phone'
            value={userData.phone}
            onChange={handleChange}
            required
            className='form-control'
          />
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Email:</label>
        <div className='col-sm-9'>
          <input
            type='email'
            name='email'
            value={userData.email}
            onChange={handleChange}
            className='form-control'
          />
        </div>
      </div>
      {/* <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Profile Picture URL:</label>
        <div className='col-sm-9'>
          <input
            type='text'
            name='profile_pic_url'
            value={userData.profile_pic_url}
            onChange={handleChange}
            className='form-control'
          />
        </div>
      </div> */}
      <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Username:</label>
        <div className='col-sm-9'>
          <input
            type='text'
            name='username'
            value={userData.username}
            onChange={handleChange}
            required
            className='form-control'
          />
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <label className='col-sm-3 col-form-label'>Password:</label>
        <div className='col-sm-9'>
          <input
            type='password'
            name='password'
            value={userData.password}
            onChange={handleChange}
            required
            className='form-control'
          />
        </div>
      </div>
      <button type='submit' className='btn btn-primary w-100'>
        Register
      </button>
    </form>
  );
};

export default RegistrationForm;
